<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <p class="tab-setting" v-if="updateService == true">
          แก้ไขบริการทางการแพทย์
        </p>
        <p class="tab-setting" v-else>เพิ่มบริการทางการแพทย์</p>
        <el-steps :active="activeStep" finish-status="success" align-center>
          <el-step title="ขั้นตอนที่ 1"></el-step>
          <el-step title="ขั้นตอนที่ 2"></el-step>
          <el-step title="ขั้นตอนที่ 3"></el-step>
        </el-steps>
        <div class="box-manage-admin">
          <el-row :gutter="30">
            <el-col :span="24" class="set-box name-list">
              <p>ข้อมูลบริการทางการแพทย์</p>
            </el-col>
            <el-form
              ref="form"
              :label-position="'top'"
              :model="form"
              :rules="rules"
            >
              <el-col :span="24" class="set-box" v-if="activeStep == 0">
                <el-row :gutter="15">
                  <el-col :span="24">
                    <el-upload
                      action="#"
                      class="box-image-upload box-profile text-center"
                      ref="upload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="uploadImage"
                      :show-file-list="false"
                    >
                      <img
                        v-if="showDefault == false || form.illustration == ''"
                        src="../assets/image/user.png"
                        class="showavatar 1"
                      />
                      <img
                        v-else-if="typeof image.raw == 'undefined'"
                        :src="
                          callImage +
                          'service/' +
                          routeParams.serviceId +
                          '/' +
                          form.illustration
                        "
                        class="showavatar 1s"
                      />
                      <img
                        v-else
                        :src="getUrlImage(image)"
                        class="showavatar"
                      />
                      <div slot="default">
                        <p class="selectImg">เลือกรูปภาพประกอบ</p>
                      </div>
                    </el-upload>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="หน่วยงานที่อยู่ภายใต้" prop="under">
                      <el-select
                        v-model="form.under"
                        placeholder="กรุณาเลือกหน่วยงาน"
                      >
                        <el-option
                          v-for="item in dataUnder"
                          :key="item._id"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ประเภทศูนย์บริการ" prop="type">
                      <el-select
                        v-model="form.type"
                        placeholder="กรุณาเลือกประเภทศูนย์บริการ"
                      >
                        <el-option
                          v-for="item in dataType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อภาษาไทย" prop="nameTH">
                      <el-input v-model="form.nameTH">
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อภาษาอังกฤษ" prop="nameEN">
                      <el-input v-model="form.nameEN"></el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="หมายเลขโทรศัพท์">
                      <el-input
                        v-model="form.phone"
                        maxlength="9"
                        @keypress.native="isNumber($event)"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="แฟกซ์">
                      <el-input
                        v-model="form.fax"
                        maxlength="9"
                        @keypress.native="isNumber($event)"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="Website">
                      <el-input
                        v-model="form.website"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="Facebook">
                      <el-input
                        v-model="form.facebook"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="Email">
                      <el-input v-model="form.email"></el-input> </el-form-item
                  ></el-col>

                  <el-col :span="24">
                    <el-upload
                      class="upload-demo"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :on-success="savelogo"
                      :on-remove="handleRemove"
                      :file-list="fileList"
                      :limit="1"
                      list-type="picture"
                    >
                      <el-button size="small" class="btn-upload"
                        >คลิกเพื่ออัปโหลดไอคอนของศูนย์บริการ
                      </el-button>
                      <div slot="tip" class="el-upload__tip">
                        กรุณาเลือกไฟล์นามสกุล jpg และ png ที่มีขนาดน้อยกว่า 30kb
                      </div>
                    </el-upload>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ที่อยู่" prop="address">
                      <el-input v-model="form.address">
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="รหัสไปรษณีย์" prop="postcode">
                      <ThailandAutoComplete
                        v-model="form.postcode"
                        type="zipcode"
                        @select="selectAddress"
                        @input="checkValid('postcode')"
                        color="#409EFF"
                        class="form-input"
                        @keypress.native="isNumber($event)"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ตำบล/แขวง" prop="subDistrict">
                      <ThailandAutoComplete
                        v-model="form.subDistrict"
                        type="district"
                        @select="selectAddress"
                        @input="checkValid('subDistrict')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="อำเภอ/เขต" prop="district">
                      <ThailandAutoComplete
                        v-model="form.district"
                        type="amphoe"
                        @select="selectAddress"
                        @input="checkValid('district')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="จังหวัด" prop="province">
                      <ThailandAutoComplete
                        v-model="form.province"
                        type="province"
                        @select="selectAddress"
                        @input="checkValid('province')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-row :gutter="15">
                      <el-col :span="12"
                        ><el-form-item label="ละติจูด"
                          ><el-input v-model="form.lat"></el-input>
                        </el-form-item> </el-col
                      ><el-col :span="12"
                        ><el-form-item label="ลองติจูด"
                          ><el-input v-model="form.lng"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col
                    :span="24"
                    v-for="(roundOpenDay, index) in form.openDay"
                    :key="index"
                  >
                    <el-row :gutter="15">
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="วันที่เปิด"
                          :prop="'openDay.' + index + '.day'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกวันที่เปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-select
                            v-model="roundOpenDay.day"
                            placeholder="กรุณาเลือกวันที่เปิด"
                          >
                            <el-option
                              v-for="item in dataDay"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="เวลาเปิด"
                          :prop="'openDay.' + index + '.open'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกเวลาเปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-time-select
                            placeholder="เวลาเปิด"
                            v-model="roundOpenDay.open"
                            :picker-options="{
                              start: '06:00',
                              step: '00:30',
                              end: '19:30',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="เวลาปิด"
                          :prop="'openDay.' + index + '.close'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกเวลาปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-time-select
                            placeholder="เวลาเปิด"
                            v-model="roundOpenDay.close"
                            :picker-options="{
                              start: '06:00',
                              step: '00:30',
                              end: '19:30',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6" class="box-openday">
                        <el-row :gutter="15">
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="add-openday"
                                @click="addRowDay()"
                                >เพิ่มวันที่เปิด</el-button
                              >
                            </el-form-item></el-col
                          >
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="del-openday"
                                @click="delRowDay(index)"
                                >ลบ</el-button
                              >
                            </el-form-item></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" :md="12" class="openHoliday">
                    <el-form-item
                      label="บริการในวันหยุดนักขัตฤกษ์"
                      prop="closedOnPublicHoliday"
                    >
                      <el-row :gutter="15">
                        <el-col :span="12">
                          <el-radio-group v-model="form.closedOnPublicHoliday">
                            <el-radio :label="true">เปิด</el-radio>
                            <!-- <el-radio label="2">ปิด</el-radio> -->
                          </el-radio-group>
                        </el-col>
                        <el-col :span="12">
                          <el-radio-group v-model="form.closedOnPublicHoliday">
                            <el-radio :label="false">ปิด</el-radio>
                          </el-radio-group>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="24" class="set-box" v-if="activeStep == 1">
                <div class="group-data-inside">
                  <p class="head-title">ข้อมูลของหน่วยงาน</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item
                        label="เลขที่ประจำตัวนิติบุคคล ของหน่วยงาน/รหัสหน่วยงาน"
                      >
                        <el-input
                          v-model="form.information.agencyCode"
                          @keypress.native="isNumber($event)"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ประเภทการให้บริการ">
                        <el-input
                          v-model="form.information.typeOfService"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="การสนับสนุนธุรกิจเป้าหมาย">
                        <el-input v-model="form.information.targetBusiness">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="group-data-inside">
                  <p class="head-title">พื้นที่การให้บริการ</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ชื่อพื้นที่การให้บริการ">
                        <el-input v-model="form.serviceArea.text">
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ขนาดพื้นที่ใช้สอย (ตร.ม.)">
                        <el-input
                          v-model="form.serviceArea.area"
                          @keypress.native="isNumber($event)"
                          maxlength="15"
                        ></el-input> </el-form-item
                    ></el-col>
                  </el-row>
                </div>
                <div class="group-data-inside">
                  <p class="head-title">บุคลากรเฉพาะทาง (จำนวนคน)</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ศาสตราจารย์">
                        <el-input
                          v-model="form.specialized.professor"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="แพทย์">
                        <el-input
                          v-model="form.specialized.physician"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ทันตแพทย์">
                        <el-input
                          v-model="form.specialized.dentist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="เภสัชกร">
                        <el-input
                          v-model="form.specialized.pharmacy"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="สัตวแพทย์">
                        <el-input
                          v-model="form.specialized.veterinary"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="พยาบาล">
                        <el-input
                          v-model="form.specialized.nurse"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="นักเทคนิคการแพทย์">
                        <el-input
                          v-model="form.specialized.medicalTechnician"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="การแพทย์">
                        <el-input
                          v-model="form.specialized.medicalRadiologist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="กายภาพบำบัด">
                        <el-input
                          v-model="form.specialized.physicalTherapist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ผู้ช่วยพยาบาล">
                        <el-input
                          v-model="form.specialized.nursingAssistant"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="อื่นๆ">
                        <el-input
                          v-model="form.specialized.etc"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                  </el-row>
                </div>
                <div class="group-data-inside">
                  <p class="head-title">บุคลากรสนับสนุน (จำนวนคน)</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ศาสตราจารย์">
                        <el-input
                          v-model="form.support.professor"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="แพทย์">
                        <el-input
                          v-model="form.support.physician"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ทันตแพทย์">
                        <el-input
                          v-model="form.support.dentist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="เภสัชกร">
                        <el-input
                          v-model="form.support.pharmacy"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="สัตวแพทย์">
                        <el-input
                          v-model="form.support.veterinary"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="พยาบาล">
                        <el-input
                          v-model="form.support.nurse"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="นักเทคนิคการแพทย์">
                        <el-input
                          v-model="form.support.medicalTechnician"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="การแพทย์">
                        <el-input
                          v-model="form.support.medicalRadiologist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="กายภาพบำบัด">
                        <el-input
                          v-model="form.support.physicalTherapist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ผู้ช่วยพยาบาล">
                        <el-input
                          v-model="form.support.nursingAssistant"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="นักวิทยาศาสตร์">
                        <el-input
                          v-model="form.support.scientist"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="นักวิจัย">
                        <el-input
                          v-model="form.support.researcher"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ผู้ช่วยวิจัย">
                        <el-input
                          v-model="form.support.researchAssistant"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="งานทั่วไป">
                        <el-input
                          v-model="form.support.backOffice"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="อื่นๆ">
                        <el-input
                          v-model="form.support.etc"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="24" class="set-box" v-if="activeStep == 2">
                <div class="group-data-inside">
                  <p class="head-title">นักศึกษา (จำนวนคน)</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ปริญญาตรี">
                        <el-input
                          v-model="form.student.bachelor"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="จบการศึกษา">
                        <el-input
                          v-model="form.student.graduate"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="มหาบัณฑิต">
                        <el-input
                          v-model="form.student.master"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        >
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="ปริญญาเอก">
                        <el-input
                          v-model="form.student.phD"
                          @keypress.native="isNumber($event)"
                          maxlength="6"
                        ></el-input> </el-form-item
                    ></el-col>
                  </el-row>
                </div>
                <div class="group-data-inside">
                  <p class="head-title">ข้อมูลของอุปกรณ์ทางการแพทย์</p>
                  <el-row :gutter="15">
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="เตียงผู้ป่วย (เตียง)">
                        <el-input v-model="form.medicalEquipment.patienBed">
                        </el-input> </el-form-item
                    ></el-col>
                    <el-col :span="24" :sm="12" :md="12">
                      <el-form-item label="หอผู้ป่วย (หอ)">
                        <el-input
                          v-model="form.medicalEquipment.ward"
                        ></el-input> </el-form-item
                    ></el-col>

                    <el-col :span="24" :md="12">
                      <el-col
                        :span="24"
                        v-for="(dataEquipment, index) in form.medicalEquipment
                          .equipment"
                        :key="index"
                      >
                        <el-row :gutter="15">
                          <el-col :span="24" :md="12">
                            <el-form-item label="อุปกรณ์ทางการแพทย์">
                              <el-input v-model="dataEquipment.text">
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :md="6" class="box-openday">
                            <el-form-item label="เพิ่ม">
                              <el-button
                                type="primary"
                                class="add-openday"
                                @click="addEquipment()"
                                >เพิ่มอุปกรณ์</el-button
                              >
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :md="6" class="box-openday">
                            <el-form-item label="เพิ่ม">
                              <el-button
                                type="primary"
                                class="del-openday"
                                @click="delEquipment(index)"
                                >ลบอุปกรณ์</el-button
                              >
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <div class="group-data-inside">
                  <p class="head-title">สาขาวิชา</p>
                  <el-row :gutter="15">
                    <el-col
                      :span="24"
                      v-for="(roundDepartment, index) in form.department"
                      :key="index"
                    >
                      <el-row :gutter="15">
                        <el-col :span="24" :md="18">
                          <el-form-item
                            label="ชื่อสาขาวิชา"
                            :prop="'department.' + index + '.name'"
                          >
                            <el-input v-model="roundDepartment.name">
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="24" :md="6" class="box-openday">
                          <el-row :gutter="15">
                            <el-col :span="12" :sm="12" :md="12">
                              <el-form-item label="เปิดปิด">
                                <el-button
                                  type="primary"
                                  class="add-openday"
                                  @click="addDepartment()"
                                  >เพิ่มวิชา</el-button
                                >
                              </el-form-item></el-col
                            >
                            <el-col :span="12" :sm="12" :md="12">
                              <el-form-item label="เปิดปิด">
                                <el-button
                                  type="primary"
                                  class="del-openday"
                                  @click="delDepartment(index)"
                                  >ลบ</el-button
                                >
                              </el-form-item></el-col
                            >
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-form>
          </el-row>
        </div>
        <div class="setbtn">
          <router-link to="/management" v-if="activeStep == 0">
            <button class="btn btn-color-dark btn-cancle">
              <span>ยกเลิก</span>
            </button>
          </router-link>
          <button
            class="btn btn-color-dark btn-cancle"
            @click="backStep()"
            v-if="activeStep != 0"
          >
            <span>ย้อนกลับ</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="nextStep()"
            v-if="activeStep != 2"
          >
            <span>ขั้นตอนต่อไป</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="updateFormService()"
            v-if="activeStep == 2 && updateService == true"
          >
            <span>บันทึก</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="createService()"
            v-else-if="activeStep == 2"
          >
            <span>บันทึก</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import ThailandAutoComplete from "vue-thailand-address-autocomplete";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    this.getServiceById();
    this.getUnder();
  },
  components: {
    ThailandAutoComplete,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    var validateEng = (rule, value, callback) => {
      let regx = /^[^ก-๛]+$/i;
      if (!regx.test(value)) {
        callback(new Error("กรุณากรอกตัวอักษรภาษาอังกฤษ"));
      } else {
        callback();
      }
    };
    var validateThai = (rule, value, callback) => {
      let regx = /^[^a-z^A-Z]+$/i;
      if (!regx.test(value)) {
        callback(new Error("กรุณากรอกตัวอักษรภาษาไทย"));
      } else {
        callback();
      }
    };
    return {
      updateService: false,
      activeStep: 0,
      callImage: process.env.VUE_APP_BASE_URL_GET,
      showDefault: false,
      showProfile: true,
      image: "",
      fileList: [],
      dataUnder: [],
      dataType: [
        {
          value: 1,
          label: "Service",
        },
        {
          value: 2,
          label: "Our Network",
        },
      ],
      dataDay: [
        {
          value: "จันทร์",
          label: "วันจันทร์",
        },
        {
          value: "อังคาร",
          label: "วันอังคาร",
        },
        {
          value: "พุธ",
          label: "วันพุธ",
        },
        {
          value: "พฤหัสบดี",
          label: "วันพฤหัสบดี",
        },
        {
          value: "ศุกร์",
          label: "วันศุกร์",
        },
        {
          value: "เสาร์",
          label: "วันเสาร์",
        },
        {
          value: "อาทิตย์",
          label: "วันอาทิตย์",
        },
      ],
      form: {
        illustration: "",
        under: "",
        type: "",
        nameTH: "",
        nameEN: "",
        phone: "",
        fax: "",
        website: "",
        facebook: "",
        email: "",
        logo: "",
        address: "",
        postcode: "",
        subDistrict: "",
        district: "",
        province: "",
        lat: 0,
        lng: 0,
        openDay: [
          {
            day: "",
            open: "",
            close: "",
          },
        ],
        information: {
          agencyCode: "",
          typeOfService: "",
          targetBusiness: "",
        },
        serviceArea: {
          area: "",
          text: "",
        },
        specialized: {
          professor: 0,
          physician: 0,
          dentist: 0,
          pharmacy: 0,
          veterinary: 0,
          nurse: 0,
          medicalTechnician: 0,
          medicalRadiologist: 0,
          physicalTherapist: 0,
          nursingAssistant: 0,
          etc: 0,
        },
        support: {
          professor: 0,
          physician: 0,
          dentist: 0,
          pharmacy: 0,
          veterinary: 0,
          nurse: 0,
          medicalTechnician: 0,
          medicalRadiologist: 0,
          physicalTherapist: 0,
          nursingAssistant: 0,
          scientist: 0,
          researcher: 0,
          researchAssistant: 0,
          backOffice: 0,
          etc: 0,
        },
        student: {
          bachelor: 0,
          graduate: 0,
          master: 0,
          phD: 0,
        },
        medicalEquipment: {
          patienBed: 0,
          ward: 0,
          equipment: [
            {
              text: "",
            },
          ],
        },
        closedOnPublicHoliday: "",
        department: [
          {
            name: "",
          },
        ],
      },
      rules: {
        closedOnPublicHoliday: [
          {
            required: true,
            message: "กรุณาเลือกการให้บริการในวันหยุดนักขัตฤกษ์",
            trigger: "change",
          },
        ],
        under: [
          {
            required: true,
            message: "กรุณาเลือกหน่วยงานที่อยู่ภายใต้",
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "กรุณาเลือกประเภทศูนย์บริการ",
            trigger: ["blur", "change"],
          },
        ],
        nameTH: [
          { required: true, message: "กรุณากรอกชื่อภาษาไทย", trigger: "blur" },
          { validator: validateThai, trigger: "blur" },
        ],
        nameEN: [
          {
            required: true,
            message: "กรุณากรอกชื่อภาษาอังกฤษ",
            trigger: "blur",
          },
          { validator: validateEng, trigger: "blur" },
        ],
        address: [
          {
            required: true,
            message: "กรุณากรอกที่อยู่",
            trigger: "blur",
          },
        ],
        subDistrict: [
          {
            required: true,
            message: "กรุณากรอกตำบล",
            trigger: ["blur", "change"],
          },
        ],
        district: [
          {
            required: true,
            message: "กรุณากรอกอำเภอ",
            trigger: ["blur", "change"],
          },
        ],
        province: [
          {
            required: true,
            message: "กรุณากรอกจังหวัด",
            trigger: ["blur", "change"],
          },
        ],
        postcode: [
          {
            required: true,
            numberValid: true,
            min: 5,
            max: 5,
            message: "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    getServiceById() {
      if (typeof this.routeParams.serviceId != "undefined") {
        HTTP.get(`service/${this.routeParams.serviceId}`)
          .then((res) => {
            if (res.data.success) {
              this.form = res.data.obj;
              this.showDefault = true;
              this.updateService = true;
              if (this.form.logo != "") {
                this.fileList.push({
                  name: this.form.logo,
                  type: "",
                  url:
                    this.callImage +
                    "service/" +
                    this.routeParams.serviceId +
                    "/" +
                    this.form.logo,
                });
              }

              console.log("getServiceById", this.form);
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("fetchService error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    backStep() {
      if (this.activeStep-- < 1) this.activeStep = 0;
    },
    nextStep() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.activeStep++ > 2) this.activeStep = 0;
        }
      });
    },
    delRowDay(index) {
      if (this.form.openDay.length > 1) {
        this.form.openDay.splice(index, 1);
      }
    },
    addRowDay() {
      this.form.openDay.push({
        day: "",
        open: "",
        close: "",
      });
    },
    addDepartment() {
      this.form.department.push({
        name: "",
      });
    },
    delDepartment(index) {
      if (this.form.department.length > 1) {
        this.form.department.splice(index, 1);
      }
    },
    addEquipment() {
      this.form.medicalEquipment.equipment.push({
        text: "",
      });
    },
    delEquipment(index) {
      if (this.form.medicalEquipment.equipment.length > 1) {
        this.form.medicalEquipment.equipment.splice(index, 1);
      }
    },
    checkValid(field) {
      this.$refs["form"].validateField(field);
    },
    selectAddress(address) {
      this.form.postcode = address.zipcode;
      this.form.subDistrict = address.district;
      this.form.district = address.amphoe;
      this.form.province = address.province;
    },
    updateFormService() {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var formData = new FormData();
      formData.append("under", this.form.under);
      formData.append("type", this.form.type);
      formData.append("nameTH", this.form.nameTH);
      formData.append("nameEN", this.form.nameEN);
      formData.append("logo", this.form.logo);
      formData.append("address", this.form.address);
      formData.append("district", this.form.district);
      formData.append("subDistrict", this.form.subDistrict);
      formData.append("province", this.form.province);
      formData.append("postcode", this.form.postcode);
      formData.append("illustration", this.form.illustration);
      formData.append("lat", this.form.lat);
      formData.append("lng", this.form.lng);
      formData.append("phone", this.form.phone);
      formData.append("fax", this.form.fax);
      formData.append("email", this.form.email);
      formData.append("website", this.form.website);
      formData.append("facebook", this.form.facebook);
      formData.append("information", JSON.stringify(this.form.information));
      formData.append("serviceArea", JSON.stringify(this.form.serviceArea));
      formData.append("specialized", JSON.stringify(this.form.specialized));
      formData.append("closedOnPublicHoliday", this.form.closedOnPublicHoliday);

      this.form.department.map((data) => {
        console.log(data);
        formData.append("department", JSON.stringify(data));
      });
      formData.append(
        "medicalEquipment",
        JSON.stringify(this.form.medicalEquipment)
      );
      formData.append("support", JSON.stringify(this.form.support));
      formData.append("student", JSON.stringify(this.form.student));
      this.form.openDay.map((data) => {
        console.log(data);
        formData.append("openDay", JSON.stringify(data));
      });
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.put(`service/${this.routeParams.serviceId}`, formData, config)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            this.$message({
              message: "แก้ไขข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.$router.push(`/management`).catch(() => {});
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("create service error", e);
        });
    },
    getUnder() {
      HTTP.get(`unders`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            this.dataUnder = res.data.obj;
            console.log( this.dataUnder);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getUnder error", e);
        });
    },
    createService() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          formData.append("under", this.form.under);
          formData.append("type", this.form.type);
          formData.append("nameTH", this.form.nameTH);
          formData.append("nameEN", this.form.nameEN);
          formData.append("logo", this.form.logo);
          formData.append("address", this.form.address);
          formData.append("district", this.form.district);
          formData.append("subDistrict", this.form.subDistrict);
          formData.append("province", this.form.province);
          formData.append("postcode", this.form.postcode);
          formData.append("illustration", this.form.illustration);
          formData.append("lat", this.form.lat);
          formData.append("lng", this.form.lng);
          formData.append("phone", this.form.phone);
          formData.append("fax", this.form.fax);
          formData.append("email", this.form.email);
          formData.append("website", this.form.website);
          formData.append("facebook", this.form.facebook);
          formData.append("information", JSON.stringify(this.form.information));
          formData.append("serviceArea", JSON.stringify(this.form.serviceArea));
          formData.append("specialized", JSON.stringify(this.form.specialized));
          formData.append(
            "closedOnPublicHoliday",
            this.form.closedOnPublicHoliday
          );
          // formData.append("department", this.form.department);
          this.form.department.map((data) => {
            formData.append("department", JSON.stringify(data));
          });
          formData.append(
            "medicalEquipment",
            JSON.stringify(this.form.medicalEquipment)
          );
          formData.append("support", JSON.stringify(this.form.support));
          formData.append("student", JSON.stringify(this.form.student));
          this.form.openDay.map((data) => {
            console.log(data);
            formData.append("openDay", JSON.stringify(data));
          });
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`service`, formData, config)
            .then((res) => {
              if (res.data.success) {
                console.log(res.data);
                this.$message({
                  message: "บันทึกข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/management`).catch(() => {});
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("create service error", e);
            });
        }
      });
    },
    getUrlImage(image) {
      if (image != null) {
        return URL.createObjectURL(image.raw);
      } else {
        return "";
      }
    },
    uploadImage(file) {
      this.showProfile = false;
      this.image = file;
      this.showDefault = true;
      setTimeout(() => {
        this.showProfile = true;
      }, 100);
      this.form.illustration = this.image.raw;
      console.log(this.form.illustration);
    },
    savelogo(file, fileList) {
      console.log(file);
      console.log("asdasd", fileList);
      this.form.logo = fileList.raw;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script><style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
.btn-upload {
  width: 100%;
  display: block;
  padding: 11px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  background: #9e76b4;
  cursor: pointer;
}
</style>